<template>
  <list-cell :isSelected="isSelected" @onSelectClick="onSelectClick">
    <div class="sleep-cell">
      <cell-unit :style="{ width: '8%' }" :title="userName" @onClick="onRouterClick('Holography')"></cell-unit>
      <cell-unit :style="{ width: '8%' }" :title="gender" @onClick="onRouterClick('Holography')"></cell-unit>
      <cell-unit :style="{ width: '10%' }" :title="age" @onClick="onRouterClick('Holography')"></cell-unit>
      <cell-unit :style="{ width: '16%' }" :title="sn" @onClick="onRouterClick('Sleep')"></cell-unit>
      <cell-unit :style="{ width: '15%' }" :title="bindTime" @onClick="onRouterClick('Sleep')"></cell-unit>
      <div :style="{ width: '15%' }">
        <cell-unit v-if="unbindTime" :title="unbindTime" @onClick="onRouterClick('Sleep')"></cell-unit>
        <cell-unit v-else title="解绑设备" color="#2E6BE6" cursor="pointer" @onClick="onUnbind"></cell-unit>
      </div>
      <cell-unit :style="{ width: '16%' }" :title="reportInfo" @onClick="onRouterClick('Sleep')"></cell-unit>
      <div class="sleep-cell-button-container" :style="{ width: '12%' }">
        <cell-unit v-if="canCheck" color="#2E6BE6" title="查看" cursor="pointer" @onClick="onCheck"></cell-unit>
        <cell-unit v-else title="/"></cell-unit>
      </div>
    </div>
    <unbind-alert v-if="isShowUnbindAlert" :model="unbindAlertModel" @onUnbindSucceedClick="onUnbindSucceedClick"
      @onCloseClick="onUnbindCloseClick"></unbind-alert>
    <sleep-list-alert v-if="isShowSleepListAlert" :uid="model.suid" :start="model.gmtCreate" :end="model.endTime"
      :hospitalId="model.nppvHospitalId" @onCloseClick="onSleepListAlertClose"></sleep-list-alert>
  </list-cell>
</template>

<script>
import CellUnit from "@c/common/paging-list/cell-unit.vue";
import ListCell from "@c/common/paging-list/list-cell.vue";
import { findKey } from "@js/object-tool.js";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    ListCell,
    CellUnit,
    SleepListAlert: () =>
      import("@c/common/alert/panel-alert/panel-alert-sleep-list.vue"),
    UnbindAlert: () =>
      import(
        "@c/common/alert/panel-alert/panel-alert-unbind-sleep-machine.vue"
      ),
  },

  props: {
    model: Object,
    isSelected: Boolean,
  },

  data() {
    return {
      //弹窗
      unbindAlertModel: undefined,
      isShowUnbindAlert: false,
      isShowSleepListAlert: false,
    };
  },

  computed: {
    userName() {
      return placeholderText(this.model.trueName);
    },

    gender() {
      return placeholderText(findKey(USER_GENDER, this.model.sex));
    },

    age() {
      return placeholderText(`${this.model.age}`);
    },

    sn() {
      return placeholderText(this.model.sn);
    },

    bindTime() {
      const dateStr = DateTool.timeStrToStr(this.model.gmtCreate, "YYYY.MM.DD HH:mm")
      return placeholderText(dateStr)
    },

    unbindTime() {
      const dateStr = DateTool.timeStrToStr(this.model.endTime, "YYYY.MM.DD HH:mm")
      return dateStr
    },

    reportInfo() {
      const validReportNum = this.model.validReportNum?.toFixed(0)
      const invalidReportNum = this.model.invalidReportNum?.toFixed(0)
      return `有效${placeholderText(validReportNum)}份，无效${placeholderText(invalidReportNum)}份`;
    },

    canCheck() {
      return this.model.validReportNum + this.model.invalidReportNum > 0;
    },
  },

  methods: {
    onSelectClick() {
      this.$emit("onSelectedClick", {
        id: this.model.id,
      });
    },

    onRouterClick(moduleName) {
      this.$emit("onRouterClick", {
        uid: this.model.uid,
        moduleName,
      });
    },

    //解绑设备
    onUnbind() {
      this.unbindAlertModel = {
        name: this.userName,
        gender: this.gender,
        mobile: this.model.mobile,
        suid: this.model.suid,
        sn: this.sn,
        start: this.model.gmtCreate,
        end:  DateTool.dateToStr(new Date()),
        hospitalId: this.model.nppvHospitalId
      };
      this.isShowUnbindAlert = true;
    },

    onUnbindCloseClick() {
      this.isShowUnbindAlert = false;
    },

    onUnbindSucceedClick() {
      this.isShowUnbindAlert = false;
      this.$emit("onUnbindSucceedClick");
    },

    //查看报告
    onCheck() {
      this.isShowSleepListAlert = true;
    },

    onSleepListAlertClose() {
      this.isShowSleepListAlert = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &-button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &-content {
      display: flex;

      &-right-button {
        margin-left: 21px;
      }
    }

    &-invalid {
      color: #b3b3b3;
      font-size: 14px;
    }
  }
}
</style>