<template>
  <div class="module-title">
    <div class="module-title-bar"></div>
    <p class="module-title-text">{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.module-title {
  display: flex;
  margin-top: 6px;

  &-bar {
    background-color: #2e6be6;
    width: 4px;
    height: 18px;
  }

  &-text {
    color: #2e6be6;
    border-bottom: 2px solid #2e6be6;
    font-size: 16px;
    height: 28px;
    margin-left: 19px;
  }
}
</style>