<template>
  <div class="list-shell">
    <div class="list-shell-top-container">
      <module-title :title="title"></module-title>
      <div class="list-shell-top-container-center" :class="model">
        <slot name="add-left"></slot>
        <add-button
          class="list-shell-top-container-center-add-user"
          title="新建患者"
          :icon="require('@imgs/main-add-user.png')"
          @onClick="onAddUser"
        ></add-button>
      </div>
      <div class="list-shell-top-container-right">
        <slot name="add-right"></slot>
      </div>
    </div>
    <slot name="content"></slot>
    <add-patient-alert v-if="isShowAddPatientAlert" @onCloseClick="onAddPatientAlertClose"
      @onSucceed="onAddPatientSucceed"></add-patient-alert>
  </div>
</template>
  
<script>
import ModuleTitle from "@c/main/common/list-shell/module-title.vue";
import AddButton from "@c/main/common/add-button.vue";
export default {
  components: {
    ModuleTitle,
    AddButton,
    AddPatientAlert: () =>
      import("@c/common/alert/panel-alert/panel-alert-add-patient/panel-alert-add-patient.vue"),
  },

  props: {
    title: String,
    model: {
      type: String,
      default: 'right'
    }
  },

  data() {
    return {
      isShowAddPatientAlert: false,
    };
  },

  methods: {
    onAddUser() {
      if (!this.$store.state.organizationModel) {
        this.$message.error('请选择机构');
        return
      }
      this.isShowAddPatientAlert = true;
    },

    onAddPatientAlertClose() {
      this.isShowAddPatientAlert = false;
    },

    onAddPatientSucceed(uid) {
      this.isShowAddPatientAlert = false;
      //跳转用户详情
      this.openUserMain(uid, "Record");
      //可伴随页面刷新等操作
      this.$emit("onAddPatientSucceed");
    },

    openUserMain(uid, moduleName) {
      let routeData = this.$router.resolve({
        path: "/user-main",
        query: {
          uid,
          moduleName,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
  
<style lang="scss" scoped>
.list-shell {
  box-sizing: border-box;
  padding: 0px 13px 26px 13px;

  &-top-container {
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-center {
      display: flex;
      align-items: center;

      &-add-user {
        width: 112px;
        height: 36px;
      }
    }

    &-right {
      display: flex;
      align-items: center;

      &-search {
        width: 341px;
        height: 36px;
        margin-left: 29px;
      }
    }
  }
}
.right {
  margin-left: auto;
}
.left {
  margin-left: 30px;
  margin-right: auto;
}
</style>