<template>
  <div class="list-title-bar">
    <list-title-section-bar-item
      v-for="(item, index) of processedTitleArray"
      :key="index"
      :model="item"
    ></list-title-section-bar-item>
    <slot name="right"></slot>
  </div>
</template>
  
  <script>
import ListTitleSectionBarItem from "@c/common/paging-list/list-title-section-bar/list-title-section-bar-item.vue";
export default {
  components: {
    ListTitleSectionBarItem,
  },

  props: {
    /**
     * [
        [
          { name: "姓名", percent: 8 },
          { name: "性别", percent: 8 },
          { name: "年龄", percent: 10},
        ],
        [
          { name: "设备序列号", percent: 16 },
          { name: "绑定时间", percent: 15 },
          { name: "解绑时间", percent: 15 },
          { name: "监测报告", percent: 16 },
        ],
        [{ name: "操作", percent: 12 }],
      ];
     */
    titleArray: Array,
  },

  computed: {
    processedTitleArray() {
      let processedTitleArray = [];
      for (const item of this.titleArray) {
        //processedItem
        let percent = 0;
        for (const subItem of item) {
          percent += subItem.percent;
        }
        let processedSubItemArray = [];
        for (const subItem of item) {
          const processedSubItem = {
            name: subItem.name,
            percent: `${(subItem.percent / percent) * 100}%`,
          };
          processedSubItemArray.push(processedSubItem);
        }
        const processedItem = {
          percent: `${percent}%`,
          subItemArray: processedSubItemArray,
        };
        processedTitleArray.push(processedItem);
      }
      return processedTitleArray;
    },
  },
};
</script>
  
  <style lang="scss" scoped>

.list-title-bar {
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  height: 54px;
  position: relative;
  display: flex;

  &-section {
    color: black;
    border-right: 1px solid rgba($color: #b3b3b3, $alpha: 0.7);
    margin: 5px 0;
    display: flex;

    :hover {
      color: #2e6be6;
    }

    &-unit {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>