<template>
  <div
    class="list-title-section-bar-item"
    :style="{ width: model.percent}"
    @mouseout="mouseout"
    @mouseover="mouseover"
  >
    <div
      class="list-title-section-bar-item-unit"
      :class="{ 'list-title-section-bar-item-unit-hover': isHover }"
      v-for="(subItem, index) of model.subItemArray"
      :key="index"
      :style="{ width: subItem.percent }"
    >
      {{ subItem.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
  },

  data() {
    return {
      isHover: false,
    };
  },

  methods: {
    mouseover() {
      this.isHover = true;
    },

    mouseout() {
      this.isHover = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-title-section-bar-item {
  color: black;
  font-size: 16px;
  border-right: 1px solid rgba($color: #b3b3b3, $alpha: 0.7);
  margin: 5px 0;
  display: flex;

  &-unit {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-hover {
      color: #2e6be6;
    }
  }
}
</style>