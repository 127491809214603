<template>
  <div>
    <list-shell ref="listShell" title="睡眠监测列表">
      <template v-slot:add-left>
        <add-button class="sleep-add-sleep-monitor" title="新建睡眠监测" :icon="require('@imgs/main-add-sleep-monitor.png')"
          @onClick="onAddSleepMonitor"></add-button>
      </template>
      <template v-slot:add-right>
        <main-input class="sleep-search" placeholder="支持姓名、手机号码、序列号查询" v-model:inputValue="keyword" @onSearch="onSearch"></main-input>
      </template>
      <template v-slot:content>
        <page-list-container height="640px" :total="total" :totalPage="totalPage" :inputCurrentPage="currentPage"
          @pagination="onPagination" :pageSize="pageSize">
          <div>
            <list-title-bar></list-title-bar>
            <list-cell v-for="(item, index) of dataArray" :key="index" :isSelected="selectedID == item.id" :model="item"
              @onRouterClick="onRouterClick" @onSelectedClick="onSelectedClick"
              @onUnbindSucceedClick="onUnbindSucceedClick"></list-cell>
          </div>
        </page-list-container>
        <bind-alert v-if="isShowBindAlert" @onBindSucceedClick="onBindSucceedClick"
          @onCloseClick="onBindCloseClick"></bind-alert>
      </template>
    </list-shell>
  </div>
</template>

<script>
import ListShell from "@c/main/common/list-shell/list-shell.vue";
import AddButton from "@c/main/common/add-button.vue";
import MainInput from "@c/main/patient/input.vue";
import ListTitleBar from "@c/main/sleep/sleep-title-bar.vue";
import ListCell from "@c/main/sleep/sleep-cell.vue";
import PageListContainer from "@c/common/paging-list/paging-list.vue";
export default {
  components: {
    ListShell,
    AddButton,
    MainInput,
    ListTitleBar,
    ListCell,
    PageListContainer,
    BindAlert: () =>
      import("@c/common/alert/panel-alert/panel-alert-bind-sleep-machine.vue"),
  },

  data() {
    return {
      selectedID: undefined,
      //搜索
      keyword: "",
      //列表
      dataArray: [],
      total: 0,
      totalPage: 0,
      currentPage: 1,
      pageSize: 9,
      //弹窗
      isShowBindAlert: false,
    };
  },
  computed: {
    organizationModel() {
      return this.$store.state.organizationModel;
    }
  },
  watch: {
    organizationModel(newVal, oldVal) {
      if (newVal?.id != oldVal?.id) {
        this.keyword = ''
        this.currentPage = 1
        this.getSleepMonitorList();
      }
    }
  },
  created() {
    this.getSleepMonitorList();
  },

  methods: {
    onSelectedClick(data) {
      this.selectedID = data.id;
    },

    onRouterClick(data) {
      this.$refs.listShell.openUserMain(data.uid, data.moduleName);
    },

    onSearch() {
      this.currentPage = 1;
      this.getSleepMonitorList();
    },

    onPagination: function (currentPage) {
      this.currentPage = currentPage;
      this.getSleepMonitorList();
    },

    //绑定弹窗
    onAddSleepMonitor() {
      if (!this.$store.state.organizationModel) {
        this.$message.error('请选择机构');
        return;
      }
      this.isShowBindAlert = true;
    },

    onBindCloseClick() {
      this.isShowBindAlert = false;
    },

    onBindSucceedClick(uid) {
      this.isShowBindAlert = false;
      //刷新列表
      this.keyword = "";
      this.currentPage = 1;
      this.getSleepMonitorList();
      //跳转个人信息的睡眠监测页面
      this.$refs.listShell.openUserMain(uid, "Sleep");
    },

    //解绑弹窗
    onUnbindSucceedClick() {
      this.$toast.showGreen("设备解绑成功")
      this.getSleepMonitorList();
    },

    // method
    async getSleepMonitorList() {
      try {
        const params = {
          keyWords: this.keyword,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          hospitalId: this.organizationModel?.id ?? undefined,
        };
        const data = await this.$api.getSleepMonitorList(params)
        this.dataArray = data.list
        this.total = data.total
        this.totalPage = data.pages
      } catch (error) {
        this.dataArray = []
        this.total = 0
        this.totalPage = 0
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep {
  &-add-sleep-monitor {
    width: 135px;
    height: 36px;
    margin-right: 16px;
  }

  &-search {
    width: 355px;
    height: 36px;
    margin-left: 29px;
  }
}
</style>