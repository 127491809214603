<template>
  <list-title-section-bar
    :titleArray="titleArray"
    fontSize="16px"
  ></list-title-section-bar>
</template>

<script>
import ListTitleSectionBar from "@c/common/paging-list/list-title-section-bar/list-title-section-bar.vue";

export default {
  components: { ListTitleSectionBar },

  computed: {
    titleArray() {
      return [
        [
          { name: "姓名", percent: 8 },
          { name: "性别", percent: 8 },
          { name: "年龄", percent: 10},
        ],
        [
          { name: "设备序列号", percent: 16 },
          { name: "绑定时间", percent: 15 },
          { name: "解绑时间", percent: 15 },
          { name: "监测报告", percent: 16 },
        ],
        [{ name: "操作", percent: 12 }],
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.list-title-bar-sleep {
  height: 54px;
  background-color: #fafafa;
  justify-content: space-around;
}
</style>